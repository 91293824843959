import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
declare var google;

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {

  location: any = '';
  autocompleteItems: any = [];
  GoogleAutocomplete: any;
  latitude: any;
  longitude: any;
  city:any;
  state:any;
  zipcode:any;

  constructor(
    public modalController: ModalController,
    public zone: NgZone,
    public storage : Storage

  ) { 
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();

    this.storage.get('user_location').then((location) => {
      if (location != null) {

        this.latitude = location.latitude;
        this.longitude = location.longitude;
        this.location = location.address;
        this.city = location.city;
        this.state = location.state;
        this.zipcode = location.zipcode;
      } 
    });
    console.log('location :>> ', this.location);
  }

  ngOnInit() {
  }

  dismiss(action) {
    console.log('action :>> ', action);
    if (action == 'ok') {

      this.modalController.dismiss({
        'action'    : action,
        'latitude'  : this.latitude,
        'longitude' : this.longitude,
        'location'  : this.location,
        'city'      : this.city,
        'state'     : this.state,
        'zipcode'   : this.zipcode,
      });

    } else if (action == 'clear') {

      this.latitude   = 0;
      this.longitude  = 0;
      this.location   = '';
      this.city       = '';
      this.state      = '';
      this.zipcode    = '';

      this.modalController.dismiss({
        'action'      : action,
        'latitude'    : this.latitude,
        'longitude'   : this.longitude,
        'location'    : this.location,
        'city'        : this.city,
        'state'       : this.state,
        'zipcode'     : this.zipcode,
      });
    } else {
      this.modalController.dismiss({
        'action': action,
      });
    }
  }

  updateSearchResults(event: any) {

    console.log('ev :>> ', event.target.value);
    console.log('location :>> ', location);

    if (event.target.value == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.location, types: ['(regions)']},
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions) {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          }
        });
      });
    console.log('autocompleteItems :>> ', this.autocompleteItems);
  }

  selectSearchResult(item) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': item.description }, (results) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      this.location = item.description;
      this.autocompleteItems = [];
      console.log('location :>> ', this.location);
      console.log('latitude :>> ', this.latitude);
      console.log('longitude :>> ', this.longitude);
      this.getGeoLocation(this.latitude,this.longitude);
    });
  }

  async getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = await new google.maps.Geocoder();
      let latlng = await new google.maps.LatLng(lat, lng);
      let request = { latLng: latlng };
      await geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let result = results;
          this.zone.run(() => {
            if (result != null) {
              result.map(data => {
                if (data.address_components.indexOf("postal_code")) {
                  data.address_components.map(value => {
                    if (value.types.indexOf("postal_code") !== -1) {
                      this.zipcode = value.long_name;
                    }
                  })

                  if (data.address_components.indexOf("locality", "political")) {
                    data.address_components.map(value => {
                      if (value.types.indexOf("locality", "political") !== -1) {
                        this.city = value.long_name;
                      }
                    })
                  }

                  if (data.address_components.indexOf("administrative_area_level_1", "political")) {
                    data.address_components.map(value => {
                      if (value.types.indexOf("administrative_area_level_1", "political") !== -1) {
                        this.state = value.long_name;
                      }
                    })
                  }

                }
              });
            }
          })
        }
      });
    }
  }

}
