import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Platform, MenuController, AlertController, Events, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ServiceForAllService } from './service-for-all.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    public selectedIndex = 0;
    public appPages = [];
    public logout = false;
    currentUser: any = {};
    userInfo: any = [];
    roles: any;
    stopSong: any;
    isPwa: boolean;
    isPlatformAndroidIos: boolean = false;
    check_mobile_or_desktop = window.navigator.userAgent.toLowerCase().includes("mobile");

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private storage: Storage,
        private radService: ServiceForAllService,
        private menuCtrl: MenuController,
        private alertCtrl: AlertController,
        public events: Events,
        public backgroundMode: BackgroundMode,
        public cd: ChangeDetectorRef,
        public navCtrl: NavController
    ) {

        this.isPlatformAndroidIos = this.radService.isPlatformAndroidIos;

        this.events.subscribe('user:login', res => {
            if (res) {
                console.log("res", res);
                this.userInfo = res;
            }
        });

        this.events.subscribe('user:update', res => {
            if (res) {
                console.log("res", res);
                this.userInfo = res;
            }
        });
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#00d9e6');
            this.storage.get('user').then(userInfo => {
                this.userInfo = userInfo;

            console.log("this.userInfo in compnent.ts: ", this.userInfo);
            if (this.userInfo != null) {
                console.log('this.userInfo :>> ', this.userInfo);
                // alert(this.userInfo.role + " role is: " + this.userInfo.roles)
                this.menuCtrl.enable(true);
                this.menuCtrl.swipeGesture(true);
                this.currentUser = this.userInfo;
                this.checkSubs();
                this.getUserProfile();
                this.logout = true;
                this.appPages = [
                    {
                        title: 'Home',
                        url: '/main-tabs',
                        icon: 'home'
                    },
                    {
                        title: 'Profile',
                        url: '/main-tabs/profile-info',
                        icon: 'person'
                    },
                ];
                if (this.userInfo.role == 'listener') {
                    this.appPages.push(
                        {
                            title: 'Artists',
                            url: '/main-tabs/artist-list',
                            icon: 'musical-notes'
                        },
                        {
                            title: 'Store',
                            url: '/main-tabs/store',
                            icon: 'basket'
                        },
                        {
                            title: 'Cart',
                            url: '/main-tabs/my-cart',
                            icon: 'cart'
                        },
                        {
                            title: 'Orders',
                            url: '/main-tabs/my-orders',
                            icon: 'basket'
                        },
                        {
                            title: 'Payment',
                            url: '/main-tabs/payment',
                            icon: 'card'
                        },
                        // {
                        //   title: 'Setup Stripe Account', 
                        //   url: '/main-tabs/creator-stripe-account',
                        //   icon: 'document'
                        // },
                        {
                            title: 'Contact',
                            url: '/main-tabs/contact-us',
                            icon: 'call'
                        },
                        // {
                        //   title: 'Primary Address',
                        //   url: '/main-tabs/address',
                        //   icon: 'pin'
                        // },
                    )
                } else {
                    this.appPages.push(
                        {
                            title: 'Store',
                            url: '/main-tabs/store',
                            icon: 'basket'
                        },
                        {
                            title: 'Cart',
                            url: '/main-tabs/my-cart',
                            icon: 'cart'
                        },
                        {
                            title: 'My Product Orders',
                            url: '/main-tabs/my-product-order',
                            icon: 'basket'
                        },
                        {
                            title: 'Orders',
                            url: '/main-tabs/my-orders',
                            icon: 'basket'
                        },
                        {
                            title: 'Payment',
                            url: '/main-tabs/payment',
                            icon: 'card'
                        },
                        {
                            title: 'Setup Stripe Account',
                            url: '/main-tabs/creator-stripe-account',
                            icon: 'document'
                        },
                        {
                            title: 'Contact Us',
                            url: '/main-tabs/contact-us',
                            icon: 'call'
                        },
                        // {
                        //   title: 'Primary Address',
                        //   url: '/main-tabs/address',
                        //   icon: 'pin'
                        // },
                    );
                    if (!this.radService.isPlatformAndroidIos) {
                        this.appPages.push(
                            {
                                title: 'Add Music',
                                url: '/main-tabs/add-edit-song',
                                icon: 'musical-notes'
                            },
                            {
                                title: 'Add Album',
                                url: '/main-tabs/add-edit-album',
                                icon: 'albums'
                            }
                        );
                        console.log("pwa 2")
                    }
                }
                if (!this.radService.isPlatformAndroidIos) {
                    this.appPages.push(
                        {
                            title: "Subscriptions",
                            url: "/listpmprosubs",
                            icon: 'basket'

                        }
                    );
                }

                this.appPages.push( 
                    {
                        title: 'Delete Account Request',
                        url: '/delete-account',
                        icon: 'person'
                    }
                );

                // if (userInfo.roles = "artist" && userInfo.subscription_plan_pmpro && userInfo.subscription_plan_pmpro.length <= 0) {
                //   this.router.navigate(['/listpmprosubs']);
                // } else {
                //   this.navCtrl.navigateRoot('/main-tabs/home');
                // }
                // this.router.navigate(['/main-tabs']);
                // f(data.roles="artist"){
                // this.router.navigate(['/listpmprosubs']);
                // }else{
            } else {
                this.logout = false;
                this.appPages = [
                    {
                        title: 'Signin / Signup',
                        url: '/sign-in',
                        icon: 'home'
                    },
                ];
                this.router.navigate(['/sign-in']);
            }
            });
            this.splashScreen.hide();
            // this.cd.detectChanges();
        });

        this.radService.loginStatus$.subscribe(data => {
            console.log("userInfo data in compnent.ts: ", data);

            if (data && data != 'logout') {

                this.userInfo = data;

                this.menuCtrl.enable(true);
                this.menuCtrl.swipeGesture(true);
                this.logout = true;
                this.appPages = [
                    // {
                    //   title: 'Songs',
                    //   url: '/songs',
                    //   icon: 'home'
                    // },
                    {
                        title: 'Home',
                        url: '/main-tabs',
                        icon: 'home'
                    },
                    {
                        title: 'Profile',
                        url: '/main-tabs/profile-info',
                        icon: 'person'
                    },
                ];
                console.log('data.roles :>> ', data.roles);
                if (data.roles == 'listener') {
                    this.appPages.push(
                        {
                            title: 'Artists',
                            url: '/main-tabs/artist-list',
                            icon: 'musical-notes'
                        },
                        {
                            title: 'Store',
                            url: '/main-tabs/store',
                            icon: 'basket'
                        },
                        {
                            title: 'Cart',
                            url: '/main-tabs/my-cart',
                            icon: 'cart'
                        },
                        {
                            title: 'Orders',
                            url: '/main-tabs/my-orders',
                            icon: 'basket'
                        },
                        {
                            title: 'Payment',
                            url: '/main-tabs/payment',
                            icon: 'card'
                        },
                        // {
                        //   title: 'Setup Stripe Account', 
                        //   url: '/main-tabs/creator-stripe-account',
                        //   icon: 'document'
                        // },
                        {
                            title: 'Contact Us',
                            url: '/main-tabs/contact-us',
                            icon: 'call'
                        },
                        // {
                        //   title: 'Primary Address',
                        //   url: '/main-tabs/address',
                        //   icon: 'pin'
                        // },
                    )
                } else {
                    this.appPages.push(
                        {
                            title: 'Store',
                            url: '/main-tabs/store',
                            icon: 'basket'
                        },
                        {
                            title: 'Cart',
                            url: '/main-tabs/my-cart',
                            icon: 'cart'
                        },
                        {
                            title: 'My Product Orders',
                            url: '/main-tabs/my-product-order',
                            icon: 'basket'
                        },
                        {
                            title: 'Orders',
                            url: '/main-tabs/my-orders',
                            icon: 'basket'
                        },
                        {
                            title: 'Payment',
                            url: '/main-tabs/payment',
                            icon: 'card'
                        },
                        // {
                        //   title: 'Subscription',
                        //   url: '/main-tabs/subscription-plan',
                        //   icon: 'basket'
                        // },
                        {
                            title: 'Setup Stripe Account',
                            url: '/main-tabs/creator-stripe-account',
                            icon: 'document'
                        },
                        {
                            title: 'Contact',
                            url: '/main-tabs/contact-us',
                            icon: 'call'
                        },
                        // {
                        //   title: 'Primary Address',
                        //   url: '/main-tabs/address',
                        //   icon: 'pin'
                        // },
                    );

                    if (!this.radService.isPlatformAndroidIos) {
                        this.appPages.push(
                            {
                                title: 'Add Music',
                                url: '/main-tabs/add-edit-song',
                                icon: 'musical-notes'
                            },
                            {
                                title: 'Add Album',
                                url: '/main-tabs/add-edit-album',
                                icon: 'albums'
                            }
                        )
                        console.log("pwa 2", this.appPages)
                    }

                }

                if (!this.radService.isPlatformAndroidIos) {
                    this.appPages.push(
                        {
                            title: "Subscriptions",
                            url: "/listpmprosubs",
                            icon: 'basket'
                        }
                    )
                    console.log("pwa 2")
                }

                this.appPages.push( 
                    {
                        title: 'Delete Account Request',
                        url: '/delete-account',
                        icon: 'person'
                    }
                );
                
            } else {
                this.logout = false;
                this.appPages = [
                    {
                        title: 'Signin / Signup',
                        url: '/sign-in',
                        icon: 'home'
                    },
                ];
                this.router.navigate(['/sign-in']);
            }
        });
        // this.cd.detectChanges();
    }

    ngOnInit() {
    }

    async doLogout() {
        this.menuCtrl.toggle();
        const alert = await this.alertCtrl.create({
            header: 'Logout',
            message: 'Are you sure?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Logout',
                    handler: () => {

                        this.logout = false;
                        this.userInfo = '';
                        this.currentUser = '';
                        this.radService.doLogout();
                        this.events.publish('user:login', '');
                        this.storage.set('user_password', '');
                        this.storage.set('user', '');
                        this.events.publish('onLogOutMusicStop', 0);
                        this.menuCtrl.enable(false);
                        this.storage.forEach((value, key) => {
                            if (key.includes("product")) {
                                let parsedData: any = JSON.parse(value);
                                let pid = parsedData.id;
                                this.storage.remove(`product_${pid}`);
                            }
                        });
                        this.router.navigate(['/sign-in']);
                    }
                }
            ]
        });
        await alert.present();
    }

    // pauseSong(){
    //   this.stopSong['logout_pause'] = 'true';
    //   let navigationExtras: NavigationExtras = {
    //     queryParams: this.stopSong
    //   };
    //   this.router.navigate(['/main-tabs'], navigationExtras);
    // }

    toggleMenu() {
        this.menuCtrl.toggle(); //Add this method to your button click function
    }

    getUserProfile() {
        this.radService.getData('getProfile/?token=' + this.currentUser.token + '&id=' + this.currentUser.user_id).subscribe(
            data => {
                this.userInfo = data;
                // Update the subscription plan
                this.storage.get('user').then(userDetails => {
                    userDetails.subscription_plan = this.userInfo.subscription_plan;
                    this.storage.set('user', userDetails);
                });

            }
        )
    }

    async checkSubs() {

        if (this.userInfo.subscription_plan_pmpro.length <= 0) {

            await this.radService.getData("listallreadyhave?token=" + this.radService.token).toPromise().then((data: any) => {
                console.log({ data })
                if (data.membershipLevelsOfUser.length <= 0) {

                } else {
                    this.userInfo.subscription_plan_pmpro = [data.membershipLevelsOfUser];
                    this.storage.set('user', this.userInfo);
                }
            }).catch(err => {
            });

        }
    }
}