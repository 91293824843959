import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-songs-list',
  templateUrl: './songs-list.page.html',
  styleUrls: ['./songs-list.page.scss'],
})
export class SongsListPage implements OnInit {

  public selectedSongs = this.navParams.get('selected_songs');
  public checkedSongs = this.navParams.get('checked_songs');

  loginUser: any = [];
  page: any = 1;
  serach_keyword: any;
  is_song: boolean = false;
  songs: any = [];
  count: any;
  songs_list: any = [];
  msg_not_found: string;

  constructor(
    private navParams: NavParams,
    public router: Router,
    public service: ServiceForAllService,
    public storage: Storage,
    public modalController: ModalController

  ) {
    this.storage.get('user').then((user) => {
      if (user) {
        this.loginUser = user;
        this.getSongs()
      } else {
        this.router.navigate(['/login']);
      }
    }, (err) => {
      console.log('err :>> ', err);
      this.router.navigate(['/login']);
    })
  }

  ngOnInit() {
  }

  async closeModal(action) {
    await this.modalController.dismiss({ selected_songs: this.selectedSongs, checked_songs: this.checkedSongs, action: action });

  }

  getSongs() {
    this.is_song = true;
    let api_data = {
      token: this.loginUser.token,
      page: this.page,
      search_text: this.serach_keyword,
    }

    this.service.sendData('get_artist_song_new', api_data).subscribe((res: any = []) => {
      this.is_song = false;

      if (res['status'] == 'ok') {
        this.songs = res['songs'];
        this.count = res['count'];
        if (this.count == 0) {
          this.msg_not_found = "No songs are available";
        }

        let i = 0;
        this.songs.forEach((p) => {
          if (this.selectedSongs.indexOf(p.id) > -1) {
            this.songs[i].isChecked = true;
          } else {
            this.songs[i].isChecked = false;
          }
          i++;
        });
      } else {
        this.service.presentToast("Something went wrong. Please try again");
      }
    }, (err) => {
      console.log('get_songs err :>> ', err);
      this.is_song = false;
      this.service.presentAlert(err.error.errormsg);
    });
  }
  loadData(event) {
    this.is_song = true;
    let api_data = {
      token: this.loginUser.token,
      page: ++this.page,
      search_text: this.serach_keyword,
    }
    this.service.sendData('get_artist_song_new', api_data).subscribe((res: any = []) => {
      this.is_song = false;
      if (res['status'] == 'ok') {
        this.songs_list = res['songs'];
        this.count = res['count'];
        let i = 0;
        this.songs_list.forEach((p) => {
          if (this.selectedSongs.indexOf(p.id) > -1) {
            this.songs_list[i].isChecked = true;
          } else {
            this.songs_list[i].isChecked = false;
          }
          i++;
        }
        );
        if (this.songs_list.length > 0) {
          this.songs = [...this.songs, ...this.songs_list];
        }
        if (this.count == 0) {
          this.msg_not_found = "No more songs";
          event.target.complete();
        }
        event.target.complete();
      }
    }, (err) => {
      event.target.complete();
      console.log('get_songs err :>> ', err);
      this.is_song = false;
      // this.service.presentAlert(err.error.errormsg);

    });
  }
  chooseSongs(item) {
    let i = 0;
    let indx;
    indx = this.selectedSongs.indexOf(item.id);
    if (indx > -1) {
      this.selectedSongs.splice(indx, 1);

      this.checkedSongs.forEach((s) => {
        if (s.id == item.id) {
          this.checkedSongs.splice(i, 1);
        }
        i++;
      });

    } else {
      this.selectedSongs.push(item.id);
      this.checkedSongs.push(item);
    }
  }

}
