import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { Device } from '@ionic-native/device/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { WebserviceService } from './webservice.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Toast } from '@ionic-native/toast/ngx'
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
// import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Media } from '@ionic-native/media/ngx';
import { DatePipe } from '@angular/common';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { BackgroundFetch, BackgroundFetchConfig } from '@ionic-native/background-fetch/ngx';
import { StreamingMedia, StreamingVideoOptions, StreamingAudioOptions } from '@ionic-native/streaming-media/ngx';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { MusicControls } from '@ionic-native/music-controls/ngx';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { BrMaskerModule } from 'br-mask';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { VideoEditor,CreateThumbnailOptions } from '@ionic-native/video-editor/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { CartEndPageModule } from './cart-end/cart-end.module';
import { ActionsPageModule } from './actions/actions.module';
import { FilterPageModule } from './filter/filter.module';
import { SongsListPageModule } from './songs-list/songs-list.module';
import { LocationPageModule } from './location/location.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MailBoxPageModule } from './mail-box/mail-box.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
  // ListcardComponent,
  ],
  imports: [
  BrMaskerModule,
  BrowserModule,
  IonicModule.forRoot({animated: false,mode: 'ios', swipeBackEnabled: true}),
  IonicStorageModule.forRoot(), 
  HttpClientModule,
  HttpModule,
  FormsModule,
  ReactiveFormsModule,
  AppRoutingModule,
  //AngularFireModule.initializeApp(environment.firebase),
  AngularFireModule.initializeApp(environment),
  AngularFireAuthModule,
  AngularFireDatabaseModule,
  Ng2GoogleChartsModule,
  CartEndPageModule,
  ActionsPageModule,
  FilterPageModule,
  SongsListPageModule,
  LocationPageModule,
  MailBoxPageModule

],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    Stripe,
    InAppBrowser,
    VideoEditor,
    Geolocation,
    StatusBar,
    SplashScreen,
    FCM,
    WebserviceService,
    ModalController,
    NativeAudio,
    Device,
    Toast,
    PhotoViewer,
    Media,
    File,
    Camera,
    FileTransfer,
    FileTransferObject,
    DatePipe,
    FilePath,
    AndroidPermissions,
    BackgroundMode,
    File,
    NativeGeocoder,
    Network,
    NativeStorage,
    // OneSignal,
    // InAppPurchase,
    BackgroundFetch,
    StreamingMedia,
    MusicControls,
    ActionSheet,
    IOSFilePicker,
    MediaCapture,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
