import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, Router} from '@angular/router';
import { NavParams,MenuController, ModalController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-cart-end',
  templateUrl: './cart-end.page.html',
  styleUrls: ['./cart-end.page.scss'],
})
export class CartEndPage implements OnInit {
    msg:any;
    page_title:any;
    redirect_page : any = '';
    constructor(
        private route: ActivatedRoute,
        public navParams: NavParams,
        public modalController: ModalController,
        public router: Router,
        ) { 
        // this.route.queryParams.subscribe(params => {
        //     // Defaults to 0 if no query param provided.
        //     this.msg = params['msg'];
        //     this.page_title=params['page_title']
        // });

        // this.route.params.subscribe(params => {
        //     console.log("navParams:", this.navParams);
        //     if(params){
        //       this.msg  = params.msg;
        //     }
            
        //     });
    }
    ngOnInit() {

    }
    closeModel(){
        // console.log("selected_teams:", this.selected_teams);
        this.modalController.dismiss({
            'dismissed': true,
        });
        let navigationExtras: NavigationExtras = {
            queryParams: {
             'payment': 'success'
            }
        };
        this.router.navigate(['/main-tabs/store'], navigationExtras);
     }

     goToStorePage(){
      // console.log("selected_teams:", this.selected_teams);
      this.modalController.dismiss({
          'dismissed': true,
      });
      let navigationExtras: NavigationExtras = {
        queryParams: {
            'payment': 'success'
        }
      };
      this.router.navigate(['/main-tabs/store'], navigationExtras);
   }
}
