import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-mail-box',
  templateUrl: './mail-box.page.html',
  styleUrls: ['./mail-box.page.scss'],
})
export class MailBoxPage implements OnInit {

  // loginUser:any = [];
  token:any;

  constructor(
    public modarlController : ModalController,
    public storage: Storage,
    public service : ServiceForAllService,
  ) { 
    // this.storage.get("user").then((user)=>{
    //   if(user){
    //     this.loginUser = user;
    //   }
    // });
  }
  ngOnInit() {
  }

  close(){
    this.modarlController.dismiss({action : 'ok'});
  }

  sendMail(){
    this.service.showLoader();
    this.service.sendData("sendmailtouser",{token :this.token}).subscribe((res:any)=>{
      if(res){
        this.service.dismissLoading();
        this.service.presentAlert(res.msg);
        this.modarlController.dismiss({action:'ok'});
      }
    },(err)=>{
      this.service.dismissLoading();
      this.modarlController.dismiss();
      console.log('err :>> ', err);
    });
  }
}
