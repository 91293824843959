import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    public storage : Storage,
    public router : Router
  ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve)=>{
      this.storage.get('user').then((user)=>{
        if(user){
          // if ((user.roles == "artist" || user.role == "artist") && user.subscription_plan_pmpro.length <= 0) {
          //   this.router.navigate(['/listpmprosubs']);
          // } else {
            this.router.navigate(['/main-tabs/home']);
          // }
          resolve(false);
        }else{
          resolve(true);
        }
      })
    });

  }
  
}
