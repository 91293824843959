import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit { 

  search_type:any;

  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
  }
  
  dismiss(type){
    this.popoverController.dismiss({'search_type' :type });
  }

}
