import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { map, retry } from 'rxjs/operators';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Observable, of, throwError, Subject } from 'rxjs';
import { Router } from '@angular/router';

const baseUrl = 'https://localrad2.betaplanets.com/';
const fullUrl = 'https://localrad2.betaplanets.com/wp-json/mobileapi/v1/';
const modFullUrl = 'https://localrad2.betaplanets.com/wp-json/pmpro/v1/';
const productUrl = 'https://localrad2.betaplanets.com/';
const siteUrl = 'https://localrad2.betaplanets.com/';

@Injectable({
    providedIn: 'root'
})
export class ServiceForAllService {
    loading: any;
    totalPosts = null;
    pages: any;
    one_id: any;
    token: any;
    consumerKey: any = 'ck_cab481c8d6aa334d07209a51aa267f72da7a28cb';
    consumerSecret: any = 'cs_00cef2d7b22153e530181eb8981822c587ea49c1';
    url = 'https://localrad2.betaplanets.com/wp-json/wp/v2/';
    productUrl = 'https://localrad2.betaplanets.com/';

    stateList = [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ];

    loginStatusSource = new Subject<any>();
    loginStatus$ = this.loginStatusSource.asObservable();
    isPlatformAndroidIos: boolean = false;

    constructor(
        private http: HttpClient,
        private loadingCtrl: LoadingController,
        private toastCtrl: ToastController,
        private platform: Platform,
        // private oneSignal: OneSignal,
        public nativeStorage: NativeStorage,
        public alertCtrl: AlertController,
        public router: Router
    ) {

        const isapp: any = (document.URL.startsWith('http://web.localrad2.betaplanets.com') || document.URL.startsWith('https://web.localrad2.betaplanets.com') || document.URL.startsWith('http://localhost:8100'));
        if (isapp) {
            console.log("PWA");
            this.isPlatformAndroidIos = false;
            console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        } else {
            console.log("Device");
            this.isPlatformAndroidIos = true;
            console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        }
    }




    getURL() {
        return siteUrl;
    }

    getData(endPoint) {
        return this.http.get(fullUrl + endPoint).pipe(
            map(res => {
                return res;
            })
        );
    }

    sendData(endPoint, data) {
        return this.http.post(fullUrl + endPoint, data).pipe(
            map(res => {
                return res;
            })
        );
    }

    getModData(endPoint, data) {
        return this.http.get(modFullUrl + endPoint + '/' + data).pipe(
            map(res => {
                return res;
            })
        );
    }
    sendModData(endPoint, data) {
        return this.http.post(modFullUrl + endPoint, data).pipe(
            map(res => {
                return res;
            })
        );
    }

    doSignin(signinInfo) {
        return this.http.post(baseUrl + 'wp-json/jwt-auth/v1/token', signinInfo)
    }

    doLogout() {
        this.loginStatusSource.next('logout');
    }

    async showLoader() {
        this.loading = await this.loadingCtrl.create({
            message: 'Please wait...',
            backdropDismiss: false,
        });
        this.loading.present();
        await this.loading.onDidDismiss();
    }

    async dismissLoading() {
        await this.loading.dismiss();
    }

    async presentToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 4000,
            position: 'bottom'
        });
        toast.present();
    }

    async presentAlert(msg) {
        let alert = await this.alertCtrl.create({
            header: 'Alert',
            message: msg,
            buttons: [{
                text: 'OK',
                handler: () => {

                }
            }]
        });
        return await alert.present();
    }
    async presentAlertMail(msg, user) {
        let alert = await this.alertCtrl.create({
            header: 'Alert',
            message: msg,
            buttons: [
                {
                    text: 'Yes',
                    handler: () => {
                        console.log("user", user);
                        this.showLoader()
                        this.sendData('sendmailtouser', user).subscribe((data: any) => {
                            console.log({ data })
                            this.dismissLoading();
                            this.presentToast(data.msg);
                            this.router.navigate(['/listpmprosubs']);
                        }, err => {
                            console.log("err", err)
                            this.dismissLoading()
                            this.presentToast("something went wrong, please try again later")
                        });
                    }
                },
                {
                    text: 'NO',
                    handler: () => {
                        console.log("no")
                    }
                }
            ]

        });
        await alert.present();
    }

    subscribePlan(payDetails) {
        console.table(payDetails);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/make_payment', {
            userid: payDetails.userid,
            card_number: payDetails.card_number,
            exp_month: payDetails.exp_month,
            exp_year: payDetails.exp_year,
            cvc: payDetails.cvc,
            card_holder_name: payDetails.name,
            post_id: payDetails.post_id,
            post_amount: payDetails.post_amount,
            token: payDetails.token,
            card_id: payDetails.card_id,
            customer: payDetails.customer
        }).pipe(
            map(res => {
                return res;
            })
        );

    }

    updateToken(userId, deviceID, deviceData, status) {
        return this.http.post(baseUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
            userid: userId,
            deviceID: deviceID,
            deviceData: deviceData,
            status: status
        }).pipe(map(res => {
            return res;
        }));
    }

    getEvents(page = 1, user_id, userToken: any = '', post_title, postPer: any = 10, byDate): Observable<any[]> {
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: postPer,
                page: '' + page
            }
        };
        let post_title_url = post_title ? ("&search=" + post_title) : "";
        return this.http.get<any[]>(this.url + 'event?_embed&token=' + userToken + "&user_id=" + user_id + "&by_date=" + byDate + post_title_url, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    getForums(page = 1, user_id, userToken: any = '', post_title, postPer: any = 10, byDate): Observable<any[]> {
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: postPer,
                page: '' + page
            }
        };
        let post_title_url = post_title ? ("&search=" + post_title) : "";
        return this.http.get<any[]>(this.url + 'forum?_embed&token=' + userToken + "&user_id=" + user_id + "&by_date=" + byDate + post_title_url, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    getSongs(page = 1, user_id, userToken: any = '', post_title, search_type: any, serach_keyword: any, latitude: any = 0, longitude: any = 0, is_location: boolean = false, postPer: any = 10): Observable<any[]> {
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: postPer,
                page: '' + page
            }
        };
        let post_title_url = post_title ? ("&search=" + post_title) : "";
        return this.http.get<any[]>(this.url + 'songs?_embed&token=' + userToken + "&user_id=" + user_id + post_title_url + "&search_type=" + search_type + "&serach_keyword=" + serach_keyword + "&latitude=" + latitude + "&longitude=" + longitude + "&is_location=" + is_location, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    user_follow_unfollow(token, leader_id, status) {
        return this.http.post(baseUrl + "wp-json/mobileapi/v1/user_follow_unfollow", {
            token: token,
            leader_id: leader_id,
            status: status
        });
    }

    get_followers(token, uid) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_followers', {
            token: token,
            uid: uid,
        }).pipe(
            map(user => {
                return user;
            })
        )
    }


    // Stripe Account Setup

    async SaveAutoConfiqure(token) {
        console.log(token);
        if (this.platform.is('cordova')) {
            //   this.oneSignal.getIds().then((id) => {
            //     this.one_id = id.userId;
            //     this.token = token;
            //     // this.saveOneSignID(this.token, this.one_id).subscribe(m => {
            //     // });
            //   });
        }
    }

    save_stripe_account_id(token, stripe_account_id) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/save_stripe_account_id', {
            token: token,
            stripe_account_id: stripe_account_id
        });
    }

    get_stripe_account_id(token) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_stripe_account_id', {
            token: token
        });
    }

    GetSetting() {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/GetSetting', {
        });
    }

    setSetting(setting) {
        if (this.platform.is('cordova')) {
            this.nativeStorage.setItem('setting', setting)
                .then(
                    () => console.log('Stored item!'),
                    error => console.error('Error storing item', error)
                );
        }
    }

    GetCards(token) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/StripeGetCards', {
            token: token,
        })
    }

    getStoreSetting() {
        if (this.platform.is('cordova')) {
            return this.nativeStorage.getItem('setting');
        }
    }

    CreateStripeUser(token, stripeToken, type: number = 0) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/CreateStripeUser', {
            token: token,
            stripeToken: stripeToken,
            createCardToken: type
        });
    }

    addCardtoBank(token, stripeToken) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/add_debit_card', {
            token: token,
            type: 'card',
            stripeToken: stripeToken
        });
    }

    unlinkAccount(user) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/stripe_delete_account', {
            token: user.token,
        });
    }

    save_stripe_user_id(token, stripe_account_id) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/save_stripe_user_id', {
            token: token,
            stripe_account_id: stripe_account_id
        });
    }

    get_stripe_user_id(token) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_stripe_user_id', {
            token: token
        });
    }

    removeCard(token, card, type) {
        return this.http.post(baseUrl + "wp-json/mobileapi/v1/DeleteCard", {
            token: token,
            card: card,
            type: type
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    updateCard(token, card, card_new, type) {
        return this.http.post(baseUrl + "wp-json/mobileapi/v1/updateCard", {
            token: token,
            card: card,
            name: card_new.name,
            expMonth: card_new.expMonth,
            expYear: card_new.expYear,
            type: type,
            default: card_new.default
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    get_products(token, product_data, page) {
        console.log("SERVICES === ", token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_all_product', {
            token: token,
            page: page,
            search_category: product_data.search_category,
            search_title: product_data.search_title,
            search_cost_lower: product_data.search_cost_lower,
            search_cost_upper: product_data.search_cost_upper,
            search_deliver: product_data.search_deliver,
            search_location: product_data.search_location,
            search_locLattitude: product_data.search_locLattitude,
            search_locLongitude: product_data.search_locLongitude,
            search_by_seller: product_data.seller_id,
            list_type: product_data.list_type
        });
    }

    // product 
    getProducts(page: number) {
        const params = new HttpParams().set('consumer_key', this.consumerKey)
            .set('consumer_secret', this.consumerSecret)
            .set('per_page', '6')
            .set('page', page.toString())
        return new Promise((resolve, reject) => {
            this.http.get(this.productUrl + 'wp-json/wc/v2/products', { params, observe: 'response' }).subscribe(data => {
                // Read the result field from the JSON response.
                resolve(data);
            });
        });
    }

    getProduct(id) {
        const params = new HttpParams().set('consumer_key', this.consumerKey)
            .set('consumer_secret', this.consumerSecret)
        return new Promise((resolve, reject) => {
            this.http.get(this.productUrl + 'wp-json/wc/v2/products/' + id, { params }).subscribe(data => {
                // Read the result field from the JSON response.
                resolve(data);
            });
        });
    }


    filterProducts(attr, attrTerm) {
        return new Promise(resolve => {
            this.http
                .get(
                    `${this.url
                    }/wp-json/wc/v3/products?attribute=${attr}&attribute_term=${attrTerm}&consumer_key=${this.consumerKey
                    }&consumer_secret=${this.consumerSecret}`
                )
                .subscribe(res => {
                    resolve(res);
                });
        });
    }

    getPaymentGateways() {
        return new Promise(resolve => {
            this.http
                .get(
                    `${this.productUrl}wp-json/wc/v2/payment_gateways?consumer_key=${this.consumerKey
                    }&consumer_secret=${this.consumerSecret}`
                )
                .subscribe(data => {
                    resolve(data);
                });
        });
    }

    createPaymentForOrderOLD(user_token, total, allDetails, capture, order_type) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/make_payment', {
            token: user_token,
            shipping: allDetails.shippingData,
            cartdata: allDetails.cartData,
            card_holder_name: allDetails.cardData.name,
            card_number: allDetails.cardData.card_num,
            exp_month: allDetails.cardData.exp_month,
            exp_year: allDetails.cardData.exp_year,
            cvc: allDetails.cardData.cvv,
            card_id: allDetails.card_id,
            post_amount: total,
            capture: capture,
            order_type: order_type
        });
    }

    createPaymentForOrder(trainer_id, user_token, total, allDetails, capture, order_type) {
        console.log("SERVICES === ", user_token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/make_payment', {
            trainer_id: trainer_id,
            token: user_token,
            shipping: allDetails.shippingData,
            billing: allDetails.billingData,
            cartdata: allDetails.cartData,
            // card_holder_name: allDetails.cardData.name,
            // card_number: allDetails.cardData.card_num,
            // exp_month: allDetails.cardData.exp_month,
            // exp_year: allDetails.cardData.exp_year,
            // cvc: allDetails.cardData.cvv,
            card_id: allDetails.card_id,
            post_amount: total,
            capture: capture,
            order_type: order_type
        });
    }

    customAddToCart(items, shippingAddress, billingAddress, user_token) {
        console.log("SERVICES === ", user_token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/custom_add_to_cart', {
            cartdata: items,
            shipping: shippingAddress,
            billing: billingAddress,
            token: user_token,
        });
    }

    createOrder(currentUser, payment_method, payment_method_title, user_id, billingAddress, shippingAddress, cartDetails) {
        const orderObj = {};
        orderObj['payment_method'] = payment_method;
        orderObj['payment_method_title'] = payment_method_title;
        orderObj['customer_id'] = user_id;
        orderObj['billing'] = billingAddress;
        orderObj['shipping'] = shippingAddress;
        orderObj['line_items'] = cartDetails;

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        const order = this.JSON_to_URLEncoded(orderObj);

        return new Promise(resolve => {
            this.http
                .post(
                    `${this.productUrl}wp-json/wc/v2/orders/?consumer_key=${this.consumerKey
                    }&consumer_secret=${this.consumerSecret}`,
                    order,
                    { headers }
                )
                .subscribe(data => {
                    this.updateUser(currentUser, billingAddress, shippingAddress);
                    resolve(data);
                });
        });
    }

    JSON_to_URLEncoded(urlEncodedString) {
        return urlEncodedString.replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16).toUpperCase()
        })
    }

    createUser(email, username, password) {
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        const data = `username=${username}&email=${email}&password=${password}`;
        return new Promise(resolve => {
            this.http
                .post(
                    `${this.productUrl}wp-json/wc/v2/customers?consumer_key=${this.consumerKey
                    }&consumer_secret=${this.consumerSecret}`,
                    data,
                    { headers: header }
                )
                .subscribe(customerData => {
                    resolve(customerData);
                });
        });
    }

    updateUser(user, billingAddress, shippingAddress) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        const data = {
            first_name: user.name,
            last_name: user.name,
            billing: billingAddress,
            shipping: shippingAddress
        };

        const covertedData = this.JSON_to_URLEncoded(data);

        return new Promise(resolve => {
            this.http
                .post(
                    `${this.productUrl}/wp-json/wc/v2/customers/${user.user_id}/?consumer_key=${this.consumerKey
                    }&consumer_secret=${this.consumerSecret}`,
                    covertedData,
                    { headers }
                )
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    getBaseUrl() {
        return baseUrl;
    }
    deletePost(token, post_id) {
        return this.http.post(this.productUrl + 'wp-json/mobileapi/v1/delete_post', {
            token: token,
            post_id: post_id,
        }).pipe(
            map(user => {
                return user;
            })
        )
    }
    getPastOrders(customerId) {
        return new Promise(resolve => {
            this.http
                .get(
                    `${this.productUrl
                    }wp-json/wc/v2/orders?customer=${customerId}&consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`
                ).subscribe(res => {
                    resolve(res);
                });
        });
    }

    getPosts(page = 1, userToken: any = '', mypost: number = 0, c: number = 0): Observable<any[]> {
        let category_url = c ? ("&service_category=" + c) : "";
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: '10',
                page: '' + page
            }
        };

        return this.http.get<any[]>(this.url + 'forum?_embed&token=' + userToken + "&mypost=" + mypost + category_url, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    replyComment(post_id, parent_cid, post_comment, videoThumbnail, comment_attachment_url, post_content_type, user) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/reply_comment', {
            token: user.token,
            post_id: post_id,
            parent_cid: parent_cid,
            post_comment: post_comment,
            thumbnail: videoThumbnail,
            comment_attachment_url: comment_attachment_url,
            post_content_type: post_content_type
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    get_comment_details(comment_id, token) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_comment_details', {
            comment_id: comment_id,
            token: token
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    update_comment(comment) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/update_comment', comment).pipe(
            map(user => {
                return user;
            })
        )
    }

    getCommentChilds(commentID, token) {
        return this.http.get(baseUrl + 'wp-json/mobileapi/v1/get_comment_childs/?comment_id=' + commentID + '&token=' + token).pipe(
            map(user => {
                return user;
            })
        )
    }

    deleteComment(comment_id, user) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/delete_comment', {
            token: user.token,
            comment_id: comment_id,
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    likeDislike(id, type, user) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/like_dislike', {
            token: user.token,
            id: id,
            type: type
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    getPostContent(id) {
        console.log('id...', id);

        return this.http.get(this.url + 'forum/' + id + '?_embed').pipe(
            map(post => {
                post['media_url'] = post['media_url'];
                return post;
            })
        )
    }

    add_edit_product(user_token, product) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/add_edit_product', {
            token: user_token,
            product_id: product.product_id,
            category_id: product.category_id,
            title: product.title,
            description: product.description,
            price: product.price,
            address: product.address,
            deliver: product.deliver,
            quantity: product.quantity,
            locLattitude: product.locLattitude,
            locLongitude: product.locLongitude,
            product_image_id: product.product_image_id,
            _stock_status: product._stock_status
        });
    }

    get_categories(token, category_type) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_categories', {
            token: token,
            category_type: category_type,
        });
    }

    get_single_products(token, product_id) {
        console.log("SERVICES === ", token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_single_product', {
            token: token,
            product_id: product_id,
        });
    }

    delete_product(user, product_id) {
        console.log("SERVICES === ", user.token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/delete_product', {
            token: user.token,
            product_id: product_id,
        });
    }

    getAvailablePlans() {
        return this.http.post(baseUrl + "wp-json/mobileapi/v1/getAvailablePlans", {
            token: '',
        })
    }

    createSubscription(token, transcationid, receipt, plan, fromandroid) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/create_user_subscription', {
            token: token,
            transcationid: transcationid,
            receipt: receipt,
            fromandroid: fromandroid,
            plan: plan
        })
    }

    verifyRecipt(token, fromandroid) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/verifyRecipt', {
            token: token,
            fromandroid: fromandroid
        })
    }

    get_all_orders(token) {
        console.log("SERVICES === ", token);
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/get_orders', {
            token: token
        });
    }
    upload_image(data) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/upload_image', data).pipe(
            map(data => {
                return data;
            })
        )
    }
    report_as_abuse(post) {
        return this.http.post(baseUrl + 'wp-json/mobileapi/v1/report_as_abuse', post).pipe(
            map(user => {
                return user;
            })
        )
    }
}