import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MailBoxPageRoutingModule } from './mail-box-routing.module';

import { MailBoxPage } from './mail-box.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MailBoxPageRoutingModule
  ],
  declarations: [MailBoxPage]
})
export class MailBoxPageModule {}
