import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError, tap, map } from "rxjs/operators";
import { Http, Headers } from "@angular/http";
import { Device } from '@ionic-native/device/ngx';

let wpUrl = "https://localrad2.betaplanets.com/";
let fullUrl ="https://localrad2.betaplanets.com/wp-json/mobileapi/v1/";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable({
  providedIn: "root"
})
export class WebserviceService {
  url = "http://rmhcelp.betaplanets.com/wp-json/wp/v2/";
  totalPosts = null;
  pages: any;
  deviceData:any;
  constructor(private http: HttpClient, public myHttp: Http,private device:Device) {}
  
  doUpdatePassword(userData,token){
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/changePassword", {
      oldPassword: userData.oldPassword,
      password: userData.password,
      token:token,
    });
  }
  doRegister(userData) {
    console.log("SERVICES === ", userData,httpOptions);    
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/register", {
      email: userData.email,
      password: userData.password,
      first_name: userData.firstname,
      last_name: userData.lastname,
      role:userData.user_role,
      jw_auth_sec:"wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
    });
  }

  doLogin(email, password) {
    console.log("SERVICE == " + email);
    return this.http.post("https://localrad2.betaplanets.com/wp-json/jwt-auth/v1/token", {
      username: email,
      password: password,
      jw_auth_sec:"wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
    });
  }


  //@Zeeshan 
  
  getData(endPoint){
    return this.http.get(fullUrl+endPoint).pipe(
      map(data => {
        return data;
      })
    )
  }

  sendData(endPoint,data){
    return this.http.post(fullUrl+endPoint,data).pipe(
      map(data => {
        return data;
      })
    )  
  }

  currentUserPlaylist(endPoint,data){
    return this.http.post(fullUrl+endPoint,data).pipe(
      map(data => {
        return data;
      })
    )  
  }
  
  updateProfile(user_data, token) {
    console.log("SERVICES === ", user_data);
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/Update_profile_pic', {
      token: token,
      base64: user_data,
      type:'image'
    })
  }
 
//@end

}