// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  userType:'Artist',
  isclickdone:false,
  trackdata:[],
  trackFulldata:[],
  issongplayed:false,
  downloadedsongs:[],
  firebase: {
    apiKey: "AIzaSyD9icWjVDHbo38AaMRv3oHzbew8PGeVCC4",
    authDomain: "testapp-40977.firebaseapp.com",
    databaseURL: "https://testapp-40977.firebaseio.com",
    projectId: "testapp-40977",
    storageBucket: "testapp-40977.appspot.com",
    messagingSenderId: "989531065423",
    appId: "1:989531065423:web:4bf20af97f3d77134b6e3a",
    measurementId: "G-1HLPW4934V"
  }
 };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
