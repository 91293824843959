import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardGuard } from './auth/guard.guard';
import { LoginGuard } from './auth/login.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  {
    path: '', canActivate: [GuardGuard],
    loadChildren: () => import('./main-tabs/main-tabs.module').then(m => m.MainTabsPageModule)
  },
  { path: 'forgot', canActivate: [LoginGuard], loadChildren: './forgot/forgot.module#ForgotPageModule' },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'sign-up-profile', canActivate: [LoginGuard],
    loadChildren: () => import('./sign-up-profile/sign-up-profile.module').then(m => m.SignUpProfilePageModule)
  },
  {
    path: 'forgot-zone', canActivate: [LoginGuard],
    loadChildren: () => import('./forgot-zone/forgot-zone.module').then(m => m.ForgotZonePageModule)
  },
  {
    path: 'sign-up', canActivate: [LoginGuard],
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'bio-artist',
    loadChildren: () => import('./bio-artist/bio-artist.module').then(m => m.BioArtistPageModule)
  },
  {
    path: 'bio-listener',
    loadChildren: () => import('./bio-listener/bio-listener.module').then(m => m.BioListenerPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyPageModule)
  },
  {
    path: 'sign-in', canActivate: [LoginGuard],
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'main-tabs', canActivate: [GuardGuard],
    loadChildren: () => import('./main-tabs/main-tabs.module').then(m => m.MainTabsPageModule)
  },
  {
    path: 'home', canActivate: [GuardGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'profile-info', canActivate: [GuardGuard],
    loadChildren: () => import('./profile-info/profile-info.module').then(m => m.ProfileInfoPageModule)
  },
  {
    path: 'contact-us', canActivate: [GuardGuard],
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'address', canActivate: [GuardGuard],
    loadChildren: () => import('./address/address.module').then(m => m.AddressPageModule)
  },
  {
    path: 'calendar', canActivate: [GuardGuard],
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarPageModule)
  },
  {
    path: 'search', canActivate: [GuardGuard],
    loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'forums', canActivate: [GuardGuard],
    loadChildren: () => import('./forums/forums.module').then(m => m.ForumsPageModule)
  },
  {
    path: 'library', canActivate: [GuardGuard],
    loadChildren: () => import('./library/library.module').then(m => m.LibraryPageModule)
  },
  {
    path: 'my-cart', canActivate: [GuardGuard],
    loadChildren: () => import('./my-cart/my-cart.module').then(m => m.MyCartPageModule)
  },
  {
    path: 'shipping-address', canActivate: [GuardGuard],
    loadChildren: () => import('./shipping-address/shipping-address.module').then(m => m.ShippingAddressPageModule)
  },
  {
    path: 'payment-info', canActivate: [GuardGuard],
    loadChildren: () => import('./payment-info/payment-info.module').then(m => m.PaymentInfoPageModule)
  },
  {
    path: 'uploads', canActivate: [GuardGuard],
    loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsPageModule)
  },
  {
    path: 'edit-profile', canActivate: [GuardGuard],
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'change-password', canActivate: [GuardGuard],
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'primary-address', canActivate: [GuardGuard],
    loadChildren: () => import('./primary-address/primary-address.module').then(m => m.PrimaryAddressPageModule)
  },
  {
    path: 'add-event', canActivate: [GuardGuard],
    loadChildren: () => import('./add-event/add-event.module').then(m => m.AddEventPageModule)
  },
  {
    path: 'add-forums', canActivate: [GuardGuard],
    loadChildren: () => import('./add-forums/add-forums.module').then(m => m.AddForumsPageModule)
  },
  {
    path: 'managebankcards', canActivate: [GuardGuard],
    loadChildren: () => import('./managebankcards/managebankcards.module').then(m => m.ManagebankcardsPageModule)
  },
  {
    path: 'choosetype', canActivate: [GuardGuard],
    loadChildren: () => import('./choosetype/choosetype.module').then(m => m.ChoosetypePageModule)
  },
  {
    path: 'billing', canActivate: [GuardGuard],
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingPageModule)
  },
  {
    path: 'billing/:type', canActivate: [GuardGuard],
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingPageModule)
  },
  {
    path: 'billing/:type/:page', canActivate: [GuardGuard],
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingPageModule)
  },
  {
    path: 'creator-stripe-account', canActivate: [GuardGuard],
    loadChildren: () => import('./creator-stripe-account/creator-stripe-account.module').then(m => m.CreatorStripeAccountPageModule)
  },
  {
    path: 'payment', canActivate: [GuardGuard],
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'cardinfo', canActivate: [GuardGuard],
    loadChildren: () => import('./cardinfo/cardinfo.module').then(m => m.CardinfoPageModule)
  },
  {
    path: 'billingedit', canActivate: [GuardGuard],
    loadChildren: () => import('./billingedit/billingedit.module').then(m => m.BillingeditPageModule)
  },
  { path: 'billingedit/:type/:card_id/:card_last4/:card_brand/:card_exp_month/:card_exp_year/:card_name/edit/:default', canActivate: [GuardGuard], loadChildren: './billingedit/billingedit.module#BillingeditPageModule' },

  {
    path: 'add-media', canActivate: [GuardGuard],
    loadChildren: () => import('./add-media/add-media.module').then(m => m.AddMediaPageModule)
  },
  {
    path: 'radio-search', canActivate: [GuardGuard],
    loadChildren: () => import('./radio-search/radio-search.module').then(m => m.RadioSearchPageModule)
  },
  {
    path: 'radio-song', canActivate: [GuardGuard],
    loadChildren: () => import('./radio-song/radio-song.module').then(m => m.RadioSongPageModule)
  },
  {
    path: 'songs', canActivate: [GuardGuard],
    loadChildren: () => import('./songs/songs.module').then(m => m.SongsPageModule)
  },
  {
    path: 'music-shop', canActivate: [GuardGuard],
    loadChildren: () => import('./music-shop/music-shop.module').then(m => m.MusicShopPageModule)
  },
  {
    path: 'store', canActivate: [GuardGuard],
    loadChildren: () => import('./store/store.module').then(m => m.StorePageModule)
  },
  {
    path: 'details', canActivate: [GuardGuard],
    loadChildren: () => import('./details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'details/:id', canActivate: [GuardGuard],
    loadChildren: () => import('./details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'billing-address', canActivate: [GuardGuard],
    loadChildren: () => import('./billing-address/billing-address.module').then(m => m.BillingAddressPageModule)
  },
  {
    path: 'review-payment', canActivate: [GuardGuard],
    loadChildren: () => import('./review-payment/review-payment.module').then(m => m.ReviewPaymentPageModule)
  },
  {
    path: 'review-order', canActivate: [GuardGuard],
    loadChildren: () => import('./review-order/review-order.module').then(m => m.ReviewOrderPageModule)
  },
  {
    path: 'cart-end', canActivate: [GuardGuard],
    loadChildren: () => import('./cart-end/cart-end.module').then(m => m.CartEndPageModule)
  },
  {
    path: 'make-payment', canActivate: [GuardGuard],
    loadChildren: () => import('./make-payment/make-payment.module').then(m => m.MakePaymentPageModule)
  },
  {
    path: 'my-orders', canActivate: [GuardGuard],
    loadChildren: () => import('./my-orders/my-orders.module').then(m => m.MyOrdersPageModule)
  },
  {
    path: 'followers', canActivate: [GuardGuard],
    loadChildren: () => import('./followers/followers.module').then(m => m.FollowersPageModule)
  },
  {
    path: 'artist-list', canActivate: [GuardGuard],
    loadChildren: () => import('./artist-list/artist-list.module').then(m => m.ArtistListPageModule)
  },
  {
    path: 'non-login-artist-profile', canActivate: [GuardGuard],
    loadChildren: () => import('./non-login-artist-profile/non-login-artist-profile.module').then(m => m.NonLoginArtistProfilePageModule)
  },
  {
    path: 'actions', canActivate: [GuardGuard],
    loadChildren: () => import('./actions/actions.module').then(m => m.ActionsPageModule)
  },
  {
    path: 'editcomment', canActivate: [GuardGuard],
    loadChildren: () => import('./editcomment/editcomment.module').then(m => m.EditcommentPageModule)
  },
  {
    path: 'replycomment', canActivate: [GuardGuard],
    loadChildren: () => import('./replycomment/replycomment.module').then(m => m.ReplycommentPageModule)
  },
  {
    path: 'singleforum', canActivate: [GuardGuard],
    loadChildren: () => import('./singleforum/singleforum.module').then(m => m.SingleforumPageModule)
  },
  {
    path: 'singleforum/:id', canActivate: [GuardGuard],
    loadChildren: () => import('./singleforum/singleforum.module').then(m => m.SingleforumPageModule)
  },
  {
    path: 'add-edit-product', canActivate: [GuardGuard],
    loadChildren: () => import('./add-edit-product/add-edit-product.module').then(m => m.AddEditProductPageModule)
  },
  {
    path: 'single-event', canActivate: [GuardGuard],
    loadChildren: () => import('./single-event/single-event.module').then(m => m.SingleEventPageModule)
  },
  {
    path: 'album-song', canActivate: [GuardGuard],
    loadChildren: () => import('./album-song/album-song.module').then(m => m.AlbumSongPageModule)
  },
  {
    path: 'edit-song', canActivate: [GuardGuard],
    loadChildren: () => import('./edit-song/edit-song.module').then(m => m.EditSongPageModule)
  },
  {
    path: 'edit-album', canActivate: [GuardGuard],
    loadChildren: () => import('./edit-album/edit-album.module').then(m => m.EditAlbumPageModule)
  },
  {
    path: 'wallet', canActivate: [GuardGuard],
    loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletPageModule)
  },
  {
    path: 'subscription-plan', canActivate: [GuardGuard],
    loadChildren: () => import('./subscription-plan/subscription-plan.module').then(m => m.SubscriptionPlanPageModule)
  },
  {
    path: 'subscription-plan/:type', canActivate: [GuardGuard],
    loadChildren: () => import('./subscription-plan/subscription-plan.module').then(m => m.SubscriptionPlanPageModule)
  },
  {
    path: 'filter', canActivate: [GuardGuard],
    loadChildren: () => import('./filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'add-edit-playlist', canActivate: [GuardGuard],
    loadChildren: () => import('./add-edit-playlist/add-edit-playlist.module').then(m => m.AddEditPlaylistPageModule)
  },
  {
    path: 'songs-list', canActivate: [GuardGuard],
    loadChildren: () => import('./songs-list/songs-list.module').then(m => m.SongsListPageModule)
  },
  {
    path: 'playlist-details', canActivate: [GuardGuard],
    loadChildren: () => import('./playlist-details/playlist-details.module').then(m => m.PlaylistDetailsPageModule)
  },
  {
    path: 'location', canActivate: [GuardGuard],
    loadChildren: () => import('./location/location.module').then(m => m.LocationPageModule)
  },
  {
    path: 'listpmprosubs', canActivate: [GuardGuard],
    loadChildren: () => import('./listpmprosubs/listpmprosubs.module').then(m => m.ListpmprosubsPageModule)
  },
  {
    path: 'add-edit-song', canActivate: [GuardGuard],
    loadChildren: () => import('./add-edit-song/add-edit-song.module').then(m => m.AddEditSongPageModule)
  },
  {
    path: 'add-edit-album', canActivate: [GuardGuard],
    loadChildren: () => import('./add-edit-album/add-edit-album.module').then(m => m.AddEditAlbumPageModule)
  },
  {
    path: 'my-music', canActivate: [GuardGuard],
    loadChildren: () => import('./my-music/my-music.module').then(m => m.MyMusicPageModule)
  },
  {
    path: 'mail-box',
    loadChildren: () => import('./mail-box/mail-box.module').then(m => m.MailBoxPageModule)
  },

  {
    path: 'favouritesongs',
    loadChildren: () => import('./favouritesongs/favouritesongs.module').then(m => m.FavouritesongsPageModule)
  },  {
    path: 'my-product-order',
    loadChildren: () => import('./my-product-order/my-product-order.module').then( m => m.MyProductOrderPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'report-as-abuse',
    loadChildren: () => import('./report-as-abuse/report-as-abuse.module').then( m => m.ReportAsAbusePageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
